/* Colors variables are defined in the theme config file */
html {
  overflow-y: hidden;
}
/* -----------allotment override styles------------- */
.sash {
  z-index: 3;
}
.split-view-view::before {
  z-index: 3 !important;
}
/* -------------------------------------------------- */
.log-stream-box {
  padding: 0;
  width: 100%;
  transition: all 0.28s ease;
}
.log-stream-box:hover {
  cursor: pointer;
  background-color: var(--greyDark);
  width: 100%;
}

.log-stream-box.compactMode:hover,
.log-stream-box.internal-message:hover {
  cursor: default !important;
  background-color: transparent !important;
}
.log-stream-box p {
  font-family: 'Roboto Mono', monospace;
}
.log-stream-box pre {
  font-family: 'Roboto Mono', monospace;
}
.log-stream-box a {
  font-family: 'Roboto Mono', monospace;
}
.log-stream-box.internal-message {
  cursor: default;
}
.log-stream-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.log-stream-header p {
  margin: 0 30px 10px 0;
  color: #999999;
  font-size: 13px;
}
.log-stream-header.internal-message p {
  margin-right: 30px;
  color: #999999;
  font-size: 13px;
}
.log-stream-header.internal-message.error-message p {
  margin-right: 30px;
  color: #fd5750;
  font-size: 13px;
}
.log-stream-header.internal-message pre {
  margin-right: 30px;
  color: #999999;
  font-size: 13px;
}
.log-stream-header.internal-message pre a {
  color: #999999;
  font-size: 13px;
  text-decoration: none;
}
.log-stream-header.internal-message.error-message pre {
  margin-right: 30px;
  color: #fd5750;
  font-size: 13px;
}
.log-stream-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
.log-stream-log {
  flex-grow: 1;
  margin: 0 0 0 10px;
  font-size: 13px;
}
.log-stream-log * {
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 0.15px;
  font-size: 13px;
  white-space: pre-line;
}
.log-stream-log.error {
  font-family: 'Roboto Mono', monospace;
  color: #fd5750;
}
.log-stream-log.clickable .react-json-view {
  cursor: pointer !important;
}
/* Hide all other copy clipboard icons in react-json-view */
.react-json-view .copy-to-clipboard-container {
  display: none !important;
}
/* Only show the copy to clipboard button on the first element in react-json-view */
.react-json-view:hover
  .pretty-json-container
  > .object-content
  > div
  > span
  > div
  > .copy-to-clipboard-container {
  display: inherit !important;
}

/* Breaks log string values so nothing is cut off or causes horizontal scrolling */
.log-stream-header p,
.log-stream-header pre,
.log-stream-log,
.react-json-view {
  white-space: pre-wrap;
  overflow: hidden;
  word-break: break-all;
}

.recharts-bar-click .recharts-bar-rectangle {
  cursor: pointer;
}

.scatter-clickable .recharts-layer.recharts-scatter-symbol {
  cursor: pointer;
}

.MuiAccordion-root.Mui-expanded {
  margin: inherit !important;
}

.MuiAccordion-root:before {
  opacity: 0 !important;
}

.SnackbarContainer-root .SnackbarItem-contentRoot {
  background-color: var(--primary);
  color: var(--secondary);
  box-shadow: none;
}
.SnackbarContainer-root .SnackbarItem-contentRoot.SnackbarItem-variantError {
  background-color: var(--red);
  color: var(--white);
}

.SnackbarContainer-root
  .SnackbarItem-contentRoot.SnackbarItem-variantError
  .SnackbarItem-action
  button {
  color: var(--white);
}

.SnackbarContainer-root .SnackbarItem-action button {
  color: var(--greyMedium);
}

@keyframes moveToRight {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

#support {
  position: absolute;
  z-index: 2000;
  bottom: 110px;
  left: 90px;
  display: block;
  height: 450px;
  width: 300px;
  animation: slideInLeft 0.25s ease-out;
}

#supportHubspotChatWindow {
  position: relative;
  display: block;
  height: 450px;
  width: 100%;
  margin: 0 0 0 0;
}

#supportHubspotChatWindow #hubspot-conversations-inline-parent,
#supportHubspotChatWindow iframe {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  overflow: hidden;
  border-radius: 8px;
}
#supportHubspotChatWindow iframe {
  border: 1px solid var(--borderMain);
}

@media only screen and (max-width: 900px) {
  body #hubspot-messages-iframe-container.widget-align-left.dev-mode {
    display: none !important;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes indeterminateAnimation {
  0% {
    opacity: 0;
    transform: translateX(-35%) scaleX(0.35);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(100%) scaleX(0.35);
  }
}

@keyframes barPulse {
  0% {
    transform: scaleY(0.6);
    opacity: 0.3;
  }
  30% {
    transform: scaleY(0.6);
    opacity: 0.3;
  }
  50% {
    transform: scaleY(1);
    transform: scaleX(1.5);
    opacity: 1;
  }
  70% {
    transform: scaleY(0.6);
    opacity: 0.3;
  }
  100% {
    transform: scaleY(0.6);
    opacity: 0.3;
  }
}
