:root {
  --tree-item-base-spacing: 15px;
  --row-height: 35px;
}

.traceSpansTreeItemGroup {
  margin-left: 0;
  position: relative;
}

.hasSiblings {
  position: relative;
}
.hasSiblings:before {
  content: '';
  position: absolute;
  top: calc(var(--row-height) / 2 * -1);
  height: 100%;
  z-index: 1;

  left: calc(var(--indent) * var(--tree-item-base-spacing));
  width: 10px;
  border-left: 1px solid var(--borderMain);
  display: block;
}

.traceSpansTreeItem {
  height: 35px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: calc((var(--indent) * var(--tree-item-base-spacing)) + 30px);
  cursor: pointer;
}
.traceSpansTreeItem:before {
  content: '';
  position: absolute;
  top: calc(var(--row-height) / 2 * -1);
  height: 100%;
  z-index: 1;
  width: calc(var(--tree-item-base-spacing) * 2);
  left: calc(var(--indent) * var(--tree-item-base-spacing));
  border-left: 1px solid var(--borderMain);
  border-bottom: 1px solid var(--borderMain);
  display: block;
}
.traceSpansTreeItem.hideLines:before {
  display: none;
}
.traceSpansTreeItem.isRootSpan:before {
  width: var(--tree-item-base-spacing);
  left: var(--tree-item-base-spacing);
  border-left: none;
}

.isActive {
  background: var(--greyDark);
}
.traceSpansTreeItemCounterBox {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: var(--text-secondary-size);
  align-items: center;
  justify-content: space-between;
  background-color: var(--greyMedium);
  padding: 2px 6px 2px 8px;
  border-radius: 2px;
}
.traceSpansTreeItemLabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  color: var(--color);
  font-size: var(--text-secondary-size);
}
.traceSpansTreeItemLabel.hasIcon {
  margin-left: 10px;
}

.traceSpansChartAccordion {
  border-radius: 0;
  height: 100%;
  width: 100%;
}
.traceSpansChartAccordion .MuiAccordionSummary {
  padding: 0;
  justify-content: flex-start;
  min-height: var(--row-height);
  padding: 0;
}
.traceSpansChartAccordion .MuiAccordionSummaryContent {
  margin: 0 !important;
}
.traceSpansChartAccordion .MuiAccordionSummaryExpandIconWrapper {
  display: none;
}
.traceSpansChartAccordion .MuiAccordionDetails {
  display: block;
  padding: 0;
}
.traceSpansChartItem {
  padding-left: 4px;
  width: 100%;
  cursor: pointer;
  height: var(--row-height);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  padding-top: 5px;
  padding-left: var(--padding-start);
  padding-right: var(--padding-end);
}
.isColdStartSpan {
  cursor: not-allowed;
}

.eventChartDot {
  width: 6px;
  height: 6px;
  background-color: var(--color);
  border-radius: 50%;
}

.traceSpansChartItemProgressBar {
  height: 5px;
  border-radius: 5px;
  width: 100%;
  z-index: 4px;
}
.traceSpansChartItemProgressDurationText {
  white-space: nowrap;
  color: var(--color);
  font-size: var(--text-secondary-size);
  z-index: 5;
  padding: 2px 0 0 0;
}
