.isPrimaryColor {
  color: var(--primary);
}
.isError {
  color: var(--red);
}
.explorerTableCellWrapper {
  align-items: center;
  line-height: 24px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  /* color: var(--greyLight); */
}
.explorerTableCellDiv {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
}
.explorerTableCellValue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.explorerTableCellChip > div {
  background-color: var(--greyMedium);
  padding: 5px;
  border-radius: 3px;
  color: var(--primary) !important;
  font-size: 11px;
  height: auto;
  line-height: 1;
  text-align: center;
  max-width: 100%;
}
.explorerTableHeader {
  display: flex;
  gap: 1;
  align-items: center;
}
.explorerTableHeader .explorerTableHeaderGripIcon {
  margin-left: 5px;
  cursor: move;
  transition: all 0.1s ease-in-out;
  opacity: 0;
}
.explorerTableHeader:hover .explorerTableHeaderGripIcon {
  opacity: 1;
}
.explorerTableCellEventsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}
.explorerTableCellEventsNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 800;
  border-radius: 5px;
  min-width: 20px;
  height: 20px;
  padding: 3px;
  /* for warnings and sdk errors */
  background-color: var(--greyDark);
  color: var(--greyLight);
}
/* is uncaught errors */
.isEventError {
  background-color: var(--red);
  color: var(--black);
}
/* is caught errors */
.isEventPrimary {
  background-color: var(--greyDark);
  color: var(--primary);
}
/* is caught errors */
.isEventWarning {
  background-color: var(--greyDark);
  color: var(--greyLight);
}
.explorerTablePopperWrapper {
  background-color: var(--primary);
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 10px;
  color: var(--secondary);
  font-weight: bold;
}
.explorerTablePopperArrow {
  position: absolute;
  font-size: 14px;
  bottom: 0px !important;
  z-index: 100;
  left: 7px;
  height: 8px;
  width: 3px;
}
.explorerTablePopperArrow:before {
  content: '';
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.6em 0.6em 0.6em 0;
  transform: rotateZ(-90deg);
  border-color: transparent var(--primary) transparent transparent;
}
