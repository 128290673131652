:root {
  --child-spacing-left: 38px;
  --gap: 10px;
  --line-width: 1px;
  --icon-width: 30px;
  --row-spacing: 10px;
  --actions-width: 120px;
}

.RowWrapper {
  background-color: var(--secondary);
}

.RowWrapper .RowContent {
  display: grid;
  z-index: 0;
  grid-template-columns: var(--icon-width) auto var(--actions-width);
  gap: var(--gap);
  position: relative;
  padding: var(--row-spacing);
  border-bottom: 1px solid var(--greyMedium);
  background-color: var(--secondary);
}

.RowWrapper .RowContent.isSticky {
  position: sticky;
  top: 0;
  z-index: 1;
}
.RowWrapper .RowContent.isExpandable,
.RowWrapper .RowContent.isChild {
  grid-template-columns: var(--icon-width) 8px var(--icon-width) auto var(--actions-width);
}
.RowWrapper .RowContent:hover {
  cursor: pointer;
  background-color: var(--greyDark);
}
.RowWrapper .RowContent.isChild {
  /* padding-left: calc(var(--child-spacing-left) + 0px); */
  cursor: pointer;
}
/* :before & :after are used for the grey tree lines */
.RowWrapper .RowContent.isChild:before {
  content: '';
  position: absolute;
  left: calc(var(--gap) + var(--icon-width) + var(--gap) + 1px);
  top: var(--row-spacing);
  height: calc((100% - var(--row-spacing) - var(--row-spacing)) / 2);
  width: var(--gap);
  border-left: var(--line-width) solid var(--greyMedium);
  border-bottom: var(--line-width) solid var(--greyMedium);
}

.RowWrapper .RowContent.isChild.hasSibling:after {
  content: '';
  position: absolute;
  left: calc(var(--gap) + var(--icon-width) + var(--gap) + 1px);
  bottom: var(--row-spacing);
  height: calc((100% - var(--row-spacing) - var(--row-spacing)) / 2);
  width: var(--gap);
  border-left: var(--line-width) solid var(--greyMedium);
}

.RowWrapper .RowContent .hoverIcon {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(var(--icon-width) / 3);
  transition: all 0.3s ease-in-out;
  z-index: 10;
  background-color: var(--greyDark);
}
.RowWrapper .RowContent:hover .hoverIcon,
.RowWrapper .RowContent.isChecked .hoverIcon {
  display: block;
  transition: all 0.1s ease-in-out;
}
.RowWrapper .RowContent .RowIconWrapper {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.RowWrapper .RowContent .divider {
  border-color: var(--greyMedium);
  margin-left: 0;
  margin-right: auto;
}
.RowWrapper .RowContent .RowText {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  justify-content: center;
}
.RowWrapper .RowContent span.RowTagName {
  color: var(--text-secondary-color);
  font-size: var(--text-secondary-size);
}
.RowWrapper .RowContent span.RowMainTitle {
  line-height: 1.2;
  font-size: var(--text-primary-size);
  color: var(--primary);
}
.RowWrapper .RowContent span.RowMainTitle span {
  margin-left: 5px;
}

.RowWrapper .RowContent .RowTags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: calc(var(--gap) + 10px);
}

.RowActions {
  margin: auto;
  width: var(--actions-width);
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}
.RowWrapper .RowActions .hoverIcon {
  left: -40px;
  color: var(--greyLight);
  transition: all 0.1s ease-in-out;
  display: block;
}
.RowWrapper .RowActions .hoverIcon:hover {
  left: -40px;
  color: var(--primary);
  transition: all 0.1s ease-in-out;
}

.RowActions a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
  color: var(--text-secondary-color);
}
.RowActions svg {
  padding: 6px;
  border-radius: 3px;
  width: 25px;
  height: 25px;
  transition: all 0.1s ease-in-out;
  background-color: var(--greyDark);
}
.RowActions a:hover {
  color: var(--primary);
}
.RowActions.disableActions a {
  opacity: 0.3;
  cursor: default;

  pointer-events: none;
}
